<template>
  <div class="planview">

    <div v-if="isLoadingSchedule || isLoadingProgram" class="noplan">
      <div class="noplan-content">
        <img :src="TumIcon" alt="Loading" class="loading-icon"/>
        <div>
          <div class="noplan-text-headline">Loading.</div>
          <div class="noplan-text">{{
              isLoadingSchedule ?
                  'Computing a possible plan according to your preferences.' :
                  'All module data for your study program is being retrieved.'
            }}
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="errorOccurred" class="noplan">
      <div class="noplan-content">
        <span class="material-symbols-outlined noplan-icon">error</span>
        <div>
          <div class="noplan-text-headline">Internal Error</div>
          <div class="noplan-text">An unexpected error has occurred on our server.
          </div>
        </div>
        <span class="material-symbols-outlined noplan-icon">rule_settings</span>
        <div>
          <div class="noplan-text-headline">What you can do</div>
          <ul class="noplan-list">
            <li>
              To help us improve our service, please
              <a class="clickable-text" @click="openModal('ReportModal')">file a report</a>.
            </li>
            <li>
              Adjust your preferences in the sidebar to generate a new plan.
            </li>
            <li>
              If the issue persists, please reload the page. Note that any unsaved inputs will be lost.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-else-if="!reqPrefsSet" class="noplan">
      <div class="noplan-content">
        <span class="material-symbols-outlined noplan-icon">not_started</span>
        <div>
          <div class="noplan-text-headline">Welcome!</div>
          <div class="noplan-text">Set the basic choices in the sidebar to compute a possible study plan for you.
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="!addedPassedModules" class="noplan"></div>

    <div v-else-if="!hasSolution" class="noplan">
     <div class="noplan-content">
      <span class="material-symbols-outlined noplan-icon">search_off</span>
      <div>
        <div class="noplan-text-headline">Sorry, no plan found!</div>
        <div class="noplan-text">
          <div v-if="violatedConstraints.length">
            <div>Your preferences violate the following conditions in the study regulations:</div>
            <ul class="noplan-list">
              <li v-for="(constraint, index) in violatedConstraints" :key="index">
                {{ constraint }}
              </li>
            </ul>
            <br /><br />
          </div>
          <div v-else>
            There is no study plan that fulfills your preferences and also all regulations of your degree program.
          </div>
        <div>
          <div>
            This is how you may fix the problem:
          </div>
            <li>
            <b>Enter all your <a class="clickable-text" @click="openModal('PassedModulesModal')">passed modules</a></b>. Note that all TUM degrees require that you pass
            some (specified) modules until the end of your second semester. Thus, there is no valid study plan if you are beyond your second semester and you do not enter that those modules as passed.
            </li>
            <li>
              <b>More credits or exams per semester.</b> You can do this under "ADVANCED" in the sidebar. In particular, all TUM degrees have a limit for how many semester you can study them. If you select too few credits per semester, you cannot meet this limit anymore.
              <br>
            </li>
            <li v-if="this.preferences.desiredModules.length !== 0">
              <b>Fewer <a class="clickable-text" @click="openModal('DesiredModulesModal')">desired modules</a></b>. We show you only study plans that contain all of them, and you see this message if there is no such plan.
            </li>
             <li v-if="this.preferences.undesiredModules.length !== 0">
              <b>Fewer <a class="clickable-text" @click="openModal('UndesiredModulesModal')">undesired modules</a></b>. If you exclude too many modules, the remaining modules may not be enough for a valid study plan.
            </li>
            <li v-if="this.preferences.degree === 'msc' && !this.preferences.de">
              <b>More possible languages</b> for your modules. You can do this under "ADVANCED" in the sidebar.
            </li>
        </div>
        <span class="material-symbols-outlined noplan-icon">summarize</span>
        <div>
          <div class="noplan-text-headline">Incorrect? Give feedback.</div>
          <div class="noplan-text">If you think there should be a valid plan for your preferences, please <a
              class="clickable-text" @click="openModal('ReportModal')">file a
            report</a>. We will then check how we can fix this problem in the StudyPlanner.
          </div>
        </div>
      </div>
     </div>
    </div>
    </div>
    <div v-else class="plan">
      <div class="overview">
        <div class="overview-item">Summary</div>
        <div class="overview-item">ECTS: {{ totalECTS }}</div>
        <div class="overview-item">One possible plan according to your preferences. It
          is planned such that you complete your degree as soon as possible while trying to avoid overlapping lectures.
        </div>
      </div>

      <div v-for="item in plan" :key="item.semester"
           :class="{ 'semester--0': item.semester === 0, unfolded: unfoldStates[item.semester] }"
           class="semester">

        <div class="semester-details">
          <div v-if="item.semester !== 0" class="semester-overview"
               @click="toggleUnfolded(item.semester)">
            <div class="semester-info">
              <div class="semester-info-item">{{ item.semester }}</div>
              <div class="semester-info-item">{{ item.schedule.semesterDescription }}</div>
              <div class="semester-info-item">ECTS: {{ item.schedule.ects }}</div>
              <div class="semester-info-item">Exams: {{ item.schedule.examSum }}</div>
            </div>
            <div v-if="item.semester !== 0" class="semester-control">
              <div v-if="!item.preference.default" class="semester-control-item tooltip-container">
                <span class="material-symbols-outlined icon-local">toggle_on</span>
                <div class="tooltip-text left">Semester preferences set</div>
              </div>
              <div class="semester-control-item tooltip-container">
                <span :class="{ unfolded: unfoldStates[item.semester] }"
                      class="material-symbols-outlined icon-settings">settings</span>
                      <div class="tooltip-text left">Semester settings</div>
              </div>
            </div>
          </div>
          <div v-else class="semester-overview semester-overview--0">
            <div class="semester-info">
              <div class="semester-info-item">Passed modules</div>
              <div class="semester-info-item">ECTS: {{ item.schedule.ects }}</div>
            </div>
          </div>
          <div v-if="item.semester !== 0" class="semester-prefs">
            <div class="semester-prefs-column">
              <div class="semester-prefs-desc">Workload preferences</div>
              <div class="semester-prefs-item">
                <span>Min. ECTS</span>
                <input v-if="!item.preference.default" v-model="item.preference.minEcts" max=60 min=0 type="number">
                <span v-if="item.preference.default">{{ preferences.minEctsPerSemester }}</span>
              </div>
              <div class="semester-prefs-item">
                <span>Max. ECTS</span>
                <input v-if="!item.preference.default" v-model="item.preference.maxEcts" max=60 min=0 type="number">
                <span v-if="item.preference.default">{{ preferences.maxEctsPerSemester }}</span>
              </div>
              <div class="semester-prefs-item">
                <span>Min. exams</span>
                <input v-if="!item.preference.default" v-model="item.preference.minExams" max=10 min=0
                       type="number">
                <span v-if="item.preference.default">{{ preferences.minExamsPerSemester }}</span>
              </div>
              <div class="semester-prefs-item">
                <span>Max. exams</span>
                <input v-if="!item.preference.default" v-model="item.preference.maxExams" max=10 min=0
                       type="number">
                <span v-if="item.preference.default">{{ preferences.maxExamsPerSemester }}</span>
              </div>
              <div class="btnContainer">
                <button class="defaultBtn" @click.stop="toggleDefaultSettings(item)">
                  {{ item.preference.default ? "Change semester settings" : "Reset to global settings" }}
                </button>
                <button v-if="!item.preference.default" class="defaultBtn" @click="reload">
                  Apply changes
                </button>
              </div>
            </div>
            <div v-if="!preferences.ignoreTimetable" class="semester-prefs-column">
              <div class="semester-prefs-desc">Do not plan classes on:</div>
              <div v-for="day in ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']" :key="day"
                   class="semester-prefs-item">
                <span>{{ day }}</span>
                <input v-model="item.preference['free' + day.substring(0,3)]" type="checkbox" @change="reload"/>
              </div>
            </div>
          </div>
        </div>

        <div v-if="item.schedule.modules.length !== 0" class="modules">
          <div v-for="module in moduleObjects(item.schedule.modules)" :id="[module.id]"
               :key="module.id"
               :class="[{'passed': isPassed(module.id)},
               {'required': isRequired(module.id)},
               {'structure-required': isStructureRequired(module.id)},
               {'structure-optional': isStructureOptional(module.id)},
               {'desired': isDesired(module.id)},
               {'desired-sem': isDesiredInSem(module.id)},
               {'technical': isTechnical(module)}]"
               class="module">
            <div class="module-content">
              <div class="left-content">
                <div class="module-detail">{{ module.mock ? '' : module.number }}</div>
                <div v-tooltip-if-cropped class="module-name">
                  {{ module.name }}{{ isTechnical(module) ? ' bestanden' : '' }}
                </div>
                <div class="module-detail" v-if="module.ects > 0">
                  {{ module.ects }} ECTS
                </div>
                <div class="module-detail" v-else/>
              </div>
              <div v-if="item.semester !== 0 && !isTechnical(module)" class="right-content">
                <div class="right-control">
                  <div class="icon-container tooltip-container">
                    <span v-if="isRequired(module.id)"
                          class="icon-undesire material-symbols-outlined">hide_source</span>
                    <span v-else class="icon-undesire material-symbols-outlined"
                          @click="undesire(module.id)">cancel</span>
                    <div v-if="isRequired(module.id)"
                         :class="[tooltipPositions[module.id]]"
                         class="tooltip-text">Module not removable (required)
                    </div>
                    <div v-else
                         :class="[tooltipPositions[module.id]]"
                         class="tooltip-text">Add to undesired modules
                    </div>
                  </div>
                </div>
                <div v-if="isStructureRequired(module.id) || isStructureOptional(module.id)" class="right-control">
                  <div class="icon-container tooltip-container">
                    <span class="icon-track material-symbols-outlined">timeline</span>
                    <div :class="[tooltipPositions[module.id]]"
                         class="tooltip-text">Module part of track
                      {{ isStructureRequired(module.id) ? '(required)' : '(optional)' }}
                    </div>
                  </div>
                </div>
                <div class="right-control control-container">
                  <div :class="{'menu-active': showOptionsLec === module.id}"
                       class="icon-container tooltip-container options-control">
                    <span
                        class="icon-lock material-symbols-outlined"
                        @click="toggleOptions(module.id)">{{
                        isDesiredInSem(module.id, item.semester)
                            ? 'push_pin' : 'more_vert'
                      }}</span>
                    <div :class="tooltipPositions[module.id]" class="tooltip-text">
                      {{
                        isDesired(module.id) || isDesiredInSem(module.id) ?
                            'Show desire options' : 'Add to desired modules'
                      }}
                    </div>
                    <div v-if="showOptionsLec === module.id" class="options"
                         @click.stop="toggleOptions(module.id)">
                      <!-- unlock from plan -->
                      <div
                          v-if="!isRequired(module.id) && (isDesired(module.id) || isDesiredInSem(module.id, item.semester))"
                          class="option-item"
                          @click="unlock(module.id)">Remove from desired modules
                      </div>
                      <!-- lock to any semester -->
                      <div v-if="isDesiredInSem(module.id, item.semester)"
                           class="option-item"
                           @click="lock(module.id)">Keep in {{
                          isRequired(module.id) ? 'plan' : 'desired modules'
                        }},
                        but in arbitrary semester
                      </div>
                      <div
                          v-else-if="!isRequired(module.id) && !isDesired(module.id)"
                          class="option-item"
                          @click="lock(module.id)">Add to desired modules
                      </div>
                      <!-- lock to this semester -->
                      <div v-if="!isDesiredInSem(module.id, item.semester)"
                           class="option-item"
                           @click="lockToSem(module.id, item.semester)">
                        Add to desired modules and pin to this semester
                      </div>
                      <!-- move to semester -->
                      <div v-for="semester in semesterOptions(module, item.semester)" :key="semester"
                           class="option-item"
                           @click="moveToSem(module.id, semester)">Move and pin to semester
                        {{ semester }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.semester !== 0" class="module module-add"
               @click="openModal({modal: 'DesiredSemesterModulesModal', semester: item.semester})">
            <!-- other options: find_replace, action_key, explore, feature_search, travel_explore -->
            <span class="material-symbols-outlined">saved_search</span>
            <span class="module-add-text">Alternative modules</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import {mapActions, mapGetters, mapState} from "vuex"
import {getSemesterDifference, isEmpty} from "@/utils";
import {config} from "../../options.config";

export default {
  directives: {
    tooltipIfCropped: {
      mounted(el) {
        if (el.scrollHeight > el.clientHeight) {
          el.setAttribute("title", el.textContent.trim());
        }
      }
    }
  },
  data() {
    return {
      unfoldStates: {},
      tooltipPositions: {},
      showOptionsLec: "",
      TumIcon: require("@/assets/tum.svg"),
    };
  },
  computed: {
    config() {
      return config
    },
    ...mapGetters(["requiredModules", "reqPrefsSet", "addedPassedModules", "prefChangeDisabled", "totalECTS"]),
    ...mapState(["offeredSemesters", "preferences", "program", "schedule", "isLoadingSchedule",
      "isLoadingProgram", "hasSolution", "errorOccurred", "programLoaded", "violatedConstraints"]),
    semesterPreferences() {
      return this.preferences.semesterPreferences;
    },
    currentSemester() {
      return getSemesterDifference(this.preferences.currentSemester, this.preferences.startSemester) + 1;
    },
    filteredSchedule()                     {
      // Step 1: Determine the maximum semester with non-empty modules
      const maxSemesterWithModules = Object.entries(this.schedule).reduce((maxSemester, [semester, semesterSchedule]) => {
        const currentSemester = parseInt(semester);
        if (semesterSchedule.modules.length !== 0 && currentSemester > maxSemester) {
          return currentSemester;
        }
        return maxSemester;
      }, 0);

      // Step 2: Filter the schedule object
      return Object.fromEntries(
          Object.entries(this.schedule).filter(([semester, semesterSchedule]) => {
            return semesterSchedule.modules.length !== 0
                || (semester >= this.currentSemester && semester <= maxSemesterWithModules);
          })
      );
    },
    plan() {
      return Object.entries(this.filteredSchedule).map(([semester, semesterSchedule]) => {
        return {
          semester: parseInt(semester),
          schedule: semesterSchedule,
          preference: this.semesterPreferences[semester],
        };
      });
    },
    selectedStructures() {
      let selectedStructures = [];

      this.program.structures.forEach(structure => {
      const selectedElementId = this.preferences.structures[structure.id];
      if (selectedElementId) {
        const selectedElement = structure.elements.find(element => element.id === selectedElementId);
        if (selectedElement) {
          selectedStructures.push(selectedElement);
        }
      }
    });

      return selectedStructures;
    },
    requiredStructureModules() {
      const selectedStructures = this.selectedStructures;
      if (!selectedStructures.length) return [];

      let allRequiredModules = [];
      selectedStructures.forEach(structure => {
        structure.blocks.forEach(block => {
          if (block.allRequired) {
            console.log(block.modules)
            console.log(allRequiredModules)
            allRequiredModules = [...allRequiredModules, ...block.modules];
          }
        });
      });

      return allRequiredModules;
    },
    optionalStructureModules() {
      const selectedStructures = this.selectedStructures;
      if (!selectedStructures.length) return [];

      let allOptionalModules = [];
      selectedStructures.forEach(structure => {
        structure.blocks.forEach(block => {
          if (!block.allRequired) {
            allOptionalModules = [...allOptionalModules, ...block.modules];
          }
        });
      });

      return allOptionalModules;
    }
  },
  watch: {
    isLoadingSchedule(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.afterReload();
      }
    }
  },
  methods: {
    isEmpty,
    ...mapActions(["openModal"]),
    reload() {
      this.$store.dispatch("requestSchedule");
    },
    afterReload() {
      this.initializeUnfoldStates();
      this.initializeTooltipPositions();
    },
    moduleObjects(moduleIdList) {
      return this.program.modules.filter(module => moduleIdList.includes(module.id));
    },
    semesterOptions(moduleObject, semester) {
      const movedSem = this.preferences.desiredInSemModules[moduleObject.id];
      const offeredSems = this.offeredSemesters[moduleObject.id];
      const currentSem = this.currentSemester;
      if (offeredSems === undefined) {
        const maxSemesterWithModules = Object.entries(this.schedule).reduce((maxSemester, [semester, semesterSchedule]) => {
        const currentSemester = parseInt(semester);
        if (semesterSchedule.modules.length !== 0 && currentSemester > maxSemester) {
          return currentSemester;
        }
        return maxSemester;
      }, 0);
      return Array.from({ length: maxSemesterWithModules }, (_, i) => i + 1);
      }
      return offeredSems.filter(item => item !== semester && item !== movedSem && item !== 0 && item >= currentSem);

    },
    isPassed(moduleId) {
      return this.preferences.passedModules.includes(moduleId);
    },
    isRequired(moduleId) {
      return this.requiredModules.some(module => module === moduleId);
    },
    isStructureRequired(moduleId) {
      return this.requiredStructureModules.some(module => module === moduleId);
    },
    isStructureOptional(moduleId) {
      return this.optionalStructureModules.some(module => module === moduleId);
    },
    isDesired(moduleId) {
      return this.preferences.desiredModules.includes(moduleId);
    },
    isDesiredInSem(moduleId, semester = undefined) {
      const sem = this.preferences.desiredInSemModules[moduleId];
      return sem !== undefined && (semester === undefined || sem === semester);
    },
    isTechnical(module) {
      return module.submodules.length !== 0;
    },
    toggleUnfolded(semesterSchedule) {
      this.unfoldStates[semesterSchedule] = !this.unfoldStates[semesterSchedule];
    },
    initializeUnfoldStates() {
      this.unfoldStates = {};
      // Use Object.keys to iterate over the keys of the filteredSchedule object
      Object.keys(this.filteredSchedule).forEach(semester => {
        this.unfoldStates[semester] = false;
      });
    },
    initializeTooltipPositions() {
      this.tooltipPositions = {};
      Object.values(this.filteredSchedule).forEach(semesterSchedule => {
        semesterSchedule.modules.forEach(moduleId => {
          // Default value
          this.tooltipPositions[moduleId] = "right";

          const buttonEl = this.$el.querySelector(`#${moduleId} .tooltip-container`);
          if (!buttonEl) return;
          const tooltipEls = buttonEl.querySelectorAll(`#${moduleId} .tooltip-text`);
          if (!tooltipEls) return;

          const buttonRect = buttonEl.getBoundingClientRect();
          const planviewElement = document.querySelector(".planview");
          const scrollbarWidth = this.hasVerticalScrollbar(planviewElement) ? this.getScrollbarWidth(planviewElement) : 0;

          for (const tooltipEl of tooltipEls) {
            const tooltipRect = tooltipEl.getBoundingClientRect();
            // Add some button width to take care of the "left: 150%" being used on the tooltip-text
            const spaceRight = window.innerWidth - (scrollbarWidth + buttonRect.right + 1.5 * buttonRect.width + tooltipRect.width);

            if (spaceRight < 0) {
              // If one of the tooltips of a module goes left, all will to avoid jumping
              this.tooltipPositions[moduleId] = "left";
              break; // Using 'break' to exit the loop once the tooltip position is determined
            }
          }
        });
      });
    },
    toggleDefaultSettings(item) {
      item.preference.default = !item.preference.default;
      // reset local values to default ones
      item.preference.minEcts = this.preferences.minEctsPerSemester
      item.preference.maxEcts = this.preferences.maxEctsPerSemester
      item.preference.minExams = this.preferences.minExamsPerSemester
      item.preference.maxExams = this.preferences.maxExamsPerSemester

      // reload if values were set back to default
      if (item.preference.default) {
        this.reload();
      }
    },
    undesire(moduleId) {
      this.$store.commit("ADD_UNDESIRED_MODULE", moduleId);
      this.reload();
    },
    lock(moduleId) {
      if (!this.requiredModules.includes(moduleId)) {
        this.$store.commit("ADD_DESIRED_MODULE", moduleId);
      } else {
        this.$store.commit("REMOVE_DESIRED_IN_SEM_MODULE", moduleId);
      }
    },
    lockToSem(moduleId, semester) {
      this.$store.commit("ADD_DESIRED_IN_SEM_MODULE", {module: moduleId, semester: semester});
    },
    unlock(moduleId) {
      this.$store.commit("REMOVE_DESIRED_IN_SEM_MODULE", moduleId);
      this.$store.commit("REMOVE_DESIRED_MODULE", moduleId);
      this.reload();
    },
    moveToSem(moduleId, semester) {
      this.lockToSem(moduleId, semester);
      this.reload();
    },
    toggleOptions(moduleId) {
      if (this.showOptionsLec === moduleId) {
        this.showOptionsLec = "";
        document.removeEventListener("click", this.outsideClickListener);
      } else {
        if (this.showOptionsLec !== "") {
          document.removeEventListener("click", this.outsideClickListener);
        }
        this.showOptionsLec = moduleId;
        this.$nextTick(() => {
          // Use Vue's $nextTick to ensure the DOM is updated before adding the listener
          this.adjustMenuPosition();
          document.addEventListener("click", this.outsideClickListener);
        });
      }
    },
    outsideClickListener(event) {
      const menuEl = this.$el.querySelector(".options");
      const buttonEl = this.$el.querySelector(".options-control.menu-active");

      if (
          (menuEl && !menuEl.contains(event.target)) &&
          (buttonEl && !buttonEl.contains(event.target))
      ) {
        this.showOptionsLec = "";
      }
    },
    hasVerticalScrollbar(element) {
      return element.scrollHeight > element.clientHeight;
    },
    getScrollbarWidth(element) {
      return element.offsetWidth - element.clientWidth;
    },
    adjustMenuPosition() {
      const menuEl = this.$el.querySelector(".options");
      const buttonEl = this.$el.querySelector(".options-control.menu-active");

      if (!buttonEl || !menuEl) return;

      const buttonRect = buttonEl.getBoundingClientRect();
      const menuRect = menuEl.getBoundingClientRect();

      const planviewElement = document.querySelector(".planview");
      const scrollbarWidth = this.hasVerticalScrollbar(planviewElement) ? this.getScrollbarWidth(planviewElement) : 0;
      const spaceRight = window.innerWidth - (scrollbarWidth + buttonRect.right + menuRect.width);
      const spaceBelow = window.innerHeight - (buttonRect.bottom + menuRect.height);

      if (spaceRight < 0) {
        menuEl.style.left = "auto";
        menuEl.style.right = "120%";
      } else {
        menuEl.style.left = "120%";
        menuEl.style.right = "auto";
      }

      if (spaceBelow < 0) {
        menuEl.style.bottom = "0";
        menuEl.style.top = "auto";
      } else {
        menuEl.style.top = "0";
        menuEl.style.bottom = "auto";
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.adjustMenuPosition);
    window.addEventListener("resize", this.initializeTooltipPositions);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.adjustMenuPosition);
    window.removeEventListener("resize", this.initializeTooltipPositions);
  }
};
</script>

<style scoped>
.planview {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  color: #888;
  position: relative;
}

.plan {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.overview {
  display: flex;
  flex-direction: row;
  padding: 1px 10px 5px 10px;
  border-bottom: 1px solid #ccc;
  font-size: 80%;
  line-height: 150%;
  margin-top: 6px;
  gap: 20px;
}

.overview-item:first-child {
  font-weight: bold;
}

.semester {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
  font-size: 80%;
  line-height: 150%;
}

.modules {
  display: flex;
  min-height: 120px;
  padding: 0 5px 5px 5px;
  flex-wrap: wrap;
}

.semester--0 {
  background-color: #f4f4f4;
}

.semester--0 .module {
  background-color: #fcfcfc;
}

.module {
  min-width: 100px;
  max-width: 200px;
  height: 85px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
  transition: border-color 0.3s;
  background-color: white;
}

.icon-container {
  display: flex;
  align-items: center;
}


.material-symbols-outlined {
  font-size: 18px;
  color: #333A41;
  opacity: 0.4;
  transition: opacity 0.3s;
}

.material-symbols-outlined.noplan-icon {
  opacity: 0.5;
}

.module .material-symbols-outlined {
  cursor: pointer;
}

.module .material-symbols-outlined:hover {
  opacity: 1;
}

.menu-active .material-symbols-outlined {
  opacity: 1;
}

.module.required .icon-undesire, .module.structure-required .icon-undesire, .module .icon-track {
  cursor: default;
}

.module.required .icon-undesire:hover, .module.structure-required .icon-undesire:hover, .module .icon-track:hover {
  opacity: 0.3;
  cursor: default;
}

.module.desired {
  border-color: #C7D97D;
}

.module.desired .icon-lock {
  color: #C7D97D;
}

.module.desired-sem {
  border-color: #9FBA36;
}

.module.desired-sem .icon-lock {
  color: #9FBA36;
}

.module.desired .icon-lock, .module.desired-sem .icon-lock {
  opacity: 1;
}

.module.desired .icon-lock:hover, .module.desired-sem .icon-lock:hover {
  opacity: 0.7;
}

.module.technical {
  opacity: 0.6;
}

.module.structure-required:not(.module.desired, .module.desired-sem) {
  border-color: #F9BF4E;
}

.module.structure-optional:not(.module.desired, .module.desired-sem) {
  border-color: #FCE2B0;
}

.module.desired, .module.desired-sem, .module.structure-required, .module.structure-optional {
  border-width: 2px;
  padding: 9px;
}

.icon-track {
  color: #D95117;
}

.icon-track:hover {
  cursor: default;
}

.module-add {
  min-width: unset;
  width: 85px;
  transition: box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.module-add:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.module-add .material-symbols-outlined {
  font-size: 40px;
  transition: opacity 0.2s;
}

.module-add:hover .material-symbols-outlined {
  opacity: 1;
}

.module-add-text {
  text-align: center;
}

.module-content {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr 2fr 1fr;
  gap: 16px;
}

.left-content {
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-content {
  grid-column: 2 / 3;
  grid-row: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.module-detail {
  line-height: 1.5em;
  min-height: 1.5em;
}

.module-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  margin: 5px 0;
}

.semester-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.semester-overview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px 5px 10px;
}

.semester-overview:not(.semester-overview--0) {
  cursor: pointer;
  transition: background-color 0.3s;
}

.semester-overview:not(.semester-overview--0):hover {
  background-color: #EBECEF;
}

.semester-info {
  display: flex;
  justify-content: left;
}

.semester-info-item {
  margin: 5px 20px 0 0;
}

.semester-info-item:first-child {
  font-weight: bold;
}

.unfolded .semester-prefs {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 5px 0;
}

.semester-prefs {
  display: none;
}

.semester-prefs-column {
  margin: 5px;
}

.semester-prefs-desc {
  font-weight: bold;
}

.semester-prefs-item {
  display: flex;
  justify-content: space-between;
  margin: 2px 0;
}

.semester-prefs-item input {
  color: #6A757E;
  font-family: inherit;
}

.semester-control {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.semester-control-item {
  display: flex;
  align-items: flex-end;
}

.material-symbols-outlined {
  font-variation-settings: 
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}

.icon-settings {
  cursor: pointer;
  font-size: 24px; 
  color: #333; 
}

.icon-local {
  color: #9ABCE4;
}

.btnContainer {
  display: flex;
  gap: 10px;
}

.defaultBtn {
  margin-top: 5px;
  padding: 5px 10px;
  border: none;
  background-color: #999999;
  color: #ffffff;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.defaultBtn:hover {
  background-color: #777777;
}

.control-container {
  position: relative;
}

.options {
  position: absolute;
  z-index: 2;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  left: 100%; 
  top: 0;
  width: auto;
  height: auto;
  white-space: nowrap;
}

.option-item {
  cursor: pointer;
  border-radius: 4px;
  padding: 5px;
  transition: background-color 0.3s;
}

.option-item:not(.option-item-inactive):hover {
  background-color: #f0f0f0;
}

.option-item.option-item-inactive {
  color: #ccc;
  cursor: inherit;
}

.noplan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.noplan-content {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  gap: 20px;
  text-align: left;
  padding: 20px;
  max-width: 80%;
}

.noplan-icon {
  font-size: 40px;
  margin-top: 4px;
}

.noplan-text-headline {
  font-weight: bold;
}

.noplan-list {
  margin: 10px 0;
  padding-inline-start: 25px;
}

.noplan-list li:not(:last-child) {
  margin-bottom: 5px;
}

.clickable-text {
  cursor: pointer;
  text-decoration: underline;
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(1turn);
  }
}

.loading-icon {
  animation: flip 2s infinite linear;
  width: 36px;
  height: 36px;
}

</style>

