<template>
  <base-module-modal
      :added-modules="allDesiredModules"
      :hidden-modules="hiddenModules"
      :unavailable-modules="requiredModules"
      :modal-name="this.$options.name"
      @confirm-operation="updateDesiredModules"
      icon-modal="stars"
      modal-title="Manage desired modules"
      text-hint="If you cannot find a module, search for 'Special topic' (for Mathematics) or 'Module' (for other
      Sciences) and select a module of the fitting area with the right credit amount."
      unavailable-reason="required"
  >
  </base-module-modal>
</template>

<script>

import BaseModuleModal from "@/components/modals/BaseModuleModal.vue";
import {getAbsoluteSemester} from "../../utils.js";
import {mapGetters, mapState} from "vuex";

export default {
  name: "DesiredModulesModal",
  components: {BaseModuleModal},
  computed: {
    ...mapState(["preferences","nextSemesters", "program"]),
    ...mapGetters(["requiredModules", "desiredModules"]),
    hiddenModules() {
     // do not offer passed modules, undesired ones as well as modules who are not offered in the next semesters or whose frequency is 0
          const hidden =  this.$store.state.preferences.passedModules
          .concat(this.$store.state.preferences.undesiredModules);

          const startSemester = this.preferences.startSemester;
          const maxSemester = this.program.maxPeriodOfStudy + 1;

          const semesters = [];
          for (let i = 0; i <= maxSemester; i++) {
            const semester = getAbsoluteSemester(i + 1, startSemester); 
            semesters.push(semester);
          }

          this.program.modules.forEach(module => {
          const frequency = this.program.moduleFrequencies[module.id];
          const offeredSemesters = this.program.offeredSemesters[module.id] || [];
          const isOfferedInAnySemester = offeredSemesters.some(offeredSemester => 
            semesters.includes(offeredSemester)
          );
          if ((!isOfferedInAnySemester || (frequency === 0 && !isOfferedInAnySemester)) && !hidden.includes(module.id)) {
              hidden.push(module.id);
          }
      });

      return hidden;

    }, allDesiredModules() {
        return  this.preferences.desiredModules.concat(
          Object.keys(this.preferences.desiredInSemModules))  
      }
  },
  methods: {
    updateDesiredModules(markedModules) {
      const desiredModulesToRemove = this.preferences.desiredModules.filter(module => !markedModules.includes(module))
      const desiredInSemModulesToRemove = Object.keys(this.preferences.desiredInSemModules).filter(module => !markedModules.includes(module))
      const modulesToAdd = markedModules.filter(module => !this.preferences.desiredModules.includes(module))

      desiredModulesToRemove.forEach(module => {
        this.$store.commit("REMOVE_DESIRED_MODULE", module);
      })
      desiredInSemModulesToRemove.forEach(module => {
        this.$store.commit("REMOVE_DESIRED_IN_SEM_MODULE", module);
      })
      modulesToAdd.forEach(module => {
        this.$store.commit("ADD_DESIRED_MODULE", module);
      })
    }
  },
};
</script>
