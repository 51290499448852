<template>
  <div class="sidebar">
    <div class="sidebar-segment">
      <div class="sidebar-segment-header">
        <span class="sidebar-title">BASICS</span>
      </div>
      <div class="sidebar-segment-body">
        <div class="preference">
          <div class="preference-desc">Degree</div>
          <select v-model="preferences.degree" :disabled="prefChangeDisabled" @change="handleDegreeChange">
            <option disabled value="">Select a degree</option>
            <!-- Loop through all degrees to list them -->
            <option v-for="(degree, id) in config.degrees" :key="id" :value="id">
              {{ degree.name }}
            </option>
          </select>
        </div>

        <div class="preference">
          <div class="preference-desc">Major</div>
          <select v-model="preferences.major" :disabled="prefChangeDisabled || !preferences.degree" @change="handleMajorChange">
            <option disabled value="">Select a major</option>
            <!-- Loop through filtered departments based on selected degree -->
            <optgroup v-for="department in filteredDepartments" :label="department.name" :key="department.id">
              <option v-for="major in department.majors" :key="major.name" :value="major.name">
                {{ major.name }}
              </option>
            </optgroup>
          </select>
        </div>
        <div class="preference">
          <div class="preference-desc">Study start</div>
          <select v-model="preferences.startSemester" :disabled="prefChangeDisabled"
                  @change="handleStartSemesterChange">
            <option disabled value="">Select your first study semester</option>
            <option v-for="semester in config.startSemesters" :key="semester.id" :value="semester.id">
              {{ semester.name }}
            </option>
          </select>
        </div>

        <transition-group name="fade">
          <div v-for="structure in program.structures" :id="structure.id" :key="structure.id" class="preference">
            <div class="preference-desc">
              <span>{{ structure.name }}</span>
              <a v-if="structure.referenceLink"
                 class="material-symbols-outlined"
                 :href="structure.referenceLink"
                 target="_blank">menu_book</a>
            </div>
            <select :disabled="prefChangeDisabled"
                    @change="handleStructureChange(structure.id, $event.target.value)">
              <option disabled selected value="">Select a {{ structure.name.toLowerCase() }}</option>
              <option v-if="!structure.required" value="none">No {{ structure.name.toLowerCase() }}</option>
              <option v-for="elem in getFilteredElements(structure.elements, structure.id)" :key="elem.id" :value="elem.id">{{ elem.name }}
              </option>
            </select>
          </div>
        </transition-group>

        <transition name="fade">
          <div v-if="reqPrefsSet && programLoaded" class="modal-controls">
            <button :disabled="prefChangeDisabled" class="modal-control" @click="openModal('PassedModulesModal')">
              <span class="material-symbols-outlined">task_alt</span>
              <span class="modal-control-text">Select <strong>passed</strong> modules</span>
            </button>
            <button :disabled="prefChangeDisabled" class="modal-control"
                    @click="openModal('DesiredModulesModal')">
              <span class="material-symbols-outlined">stars</span>
              <span class="modal-control-text">Manage <strong>desired</strong> modules</span>
            </button>
            <button :disabled="prefChangeDisabled" class="modal-control"
                    @click="openModal('UndesiredModulesModal')">
              <span class="material-symbols-outlined">cancel</span>
              <span class="modal-control-text">Manage <strong>undesired</strong> modules</span>
            </button>
          </div>
        </transition>

      </div>
    </div>

    <div :class="{ folded: isAdvancedFolded }" class="sidebar-segment">
      <div class="sidebar-segment-header foldable" @click="toggleAdvancedFolded">
        <span class="sidebar-title">ADVANCED</span>
        <span :class="{ folded: isAdvancedFolded }" class="material-symbols-outlined icon-expand">expand_more</span>
      </div>
      <div class="sidebar-segment-body">

        <div v-if="programLoaded && selectedMajor" class="preference">
          <div class="preference-desc">Study Regulation Version</div>
          <select v-model="preferences.fpso" :disabled="prefChangeDisabled" @change="handleFpsoChange">
            <option v-for="fpso in selectedMajor.fpsos" :key="fpso.id" :value="fpso.id">
              {{ fpso.name }}
            </option>
          </select>
        </div>

        <div class="preference preference--row">
          <div class="preference-desc">Min. ECTS per semester</div>
          <input v-model.number="preferences.minEctsPerSemester" :disabled="prefChangeDisabled" max=60
                 min=0
                 type="number"
                 @input="debouncedReload"/>
        </div>

        <div class="preference preference--row">
          <div class="preference-desc">Max. ECTS per semester</div>
          <input v-model.number="maxEctsPerSemester" :disabled="prefChangeDisabled" max=60
                 min=0
                 type="number"
                 @input="debouncedReload"/>
        </div>

        <div class="preference preference--row">
          <div class="preference-desc">Min. exams per semester</div>
          <input v-model.number="preferences.minExamsPerSemester" :disabled="prefChangeDisabled" max=10
                 min=0
                 type="number"
                 @input="debouncedReload"/>
        </div>

        <div class="preference preference--row">
          <div class="preference-desc">Max. exams per semester</div>
          <div class="input-container">
            <input v-model.number="preferences.maxExamsPerSemester" :disabled="prefChangeDisabled"
                   max=10 min=0
                   type="number" @input="debouncedReload"/>
          </div>
        </div>

        <div class="preference preference--row">
          <div class="preference-desc"
               title="This tool plans each semester such that no classes overlap, given the availability of class times. By enabling this option, class times will no longer be considered.">
            Allow overlapping classes
          </div>
          <input v-model="preferences.ignoreTimetable" :disabled="prefChangeDisabled" type="checkbox"
                 @change="reload">
        </div>

        <div v-if="programLoaded && !this.preferences.germanRequired"
             class="preference preference--row">
          <div class="preference-desc">Allow modules in German</div>
          <input v-model="preferences.de" :disabled="prefChangeDisabled" type="checkbox"
                 @change="reload">
        </div>

        <div v-if="programLoaded && !this.preferences.englishRequired"
             class="preference preference--row">
          <div class="preference-desc">Allow modules in English</div>
          <input v-model="preferences.de" :disabled="prefChangeDisabled" type="checkbox"
                 @change="reload">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {config} from '../../options.config.js';

export default {
  data() {
    return {
      isAdvancedFolded: true,
      debouncedReload: null,
    };
  },
  created() {
    this.debouncedReload = this.debounce(this.reload, 1500);
  },
  computed: {
    config() {
      return config;
    },
    ...mapGetters(["reqPrefsSet", "programPrefsSet", "addedPassedModules", "prefChangeDisabled"]),
    ...mapState(["program", "preferences", "programLoaded", "errorOccurred"]),
    // Find the selected degree object
    selectedDegree() {
      // Ensure that the degree exists in the config.degrees object
      return this.config.degrees[this.preferences.degree] || null;
    },
    // Filter departments based on the selected degree
    filteredDepartments() {
      const selectedDegree = this.selectedDegree;
      return selectedDegree ? selectedDegree.departments : [];
    },
    // Find the selected major object (optional, if needed for further logic)
    selectedMajor() {
      const selectedDegree = this.selectedDegree;
      
      // If no degree is selected, return null and log a warning
      if (!selectedDegree) {
        console.warn("No degree selected.");
        return null;
      }

      // Get the departments of the selected degree
      const selectedDepartments = selectedDegree.departments || [];

      // Flatten the majors from all departments
      const majors = selectedDepartments.flatMap(department => department.majors);

      // If no majors exist, log a warning and return null
      if (!majors.length) {
        console.warn("No majors found for the selected degree:", selectedDegree);
        return null;
      }

      // Find the major that matches the selected ID
      const major = majors.find(major => major.name === this.preferences.major);

      // If the major is not found, log a warning
      if (!major) {
        console.warn("Major not found for ID:", this.preferences.major);
      }

      // Return the major if found, otherwise null
      return major || null;
    },
    selectedStructureOptions() {
      return this.program.structures.reduce((acc, structure) => {
        acc[structure.id] = this.preferences.structures[structure.id] || null;
        return acc;
      }, {});
    },
    maxEctsPerSemester: {
      get() {
        return this.program.maxEctsPerSemester;
      },
      set(value) {
        this.$store.commit('SET_MAX_ECTS_PER_SEMESTER', value);
      },
    }
  },
  methods: {
    ...mapActions(["openModal", "selectStructureOption"]),
    resetBase() {
      this.$store.commit("RESET_PROGRAM");
      this.$store.commit("RESET_SCHEDULE");
      this.$store.commit("RESET_ALL_PREFERENCES_BUT_BASE");
    },
    async loadProgram() {
      if (this.programPrefsSet) {
        await this.$store.dispatch("requestProgramDetails");
      }
    },
    reload() {
      this.$store.commit('SET_MAX_ECTS_PER_SEMESTER', this.maxEctsPerSemester);
      if (!this.errorOccurred && this.reqPrefsSet && !this.addedPassedModules) {
        this.openModal('PassedModulesModal');
      } else if (this.reqPrefsSet) {
        this.$store.dispatch("requestSchedule");
      }
    },
    handleDegreeChange() {
      this.$store.commit("RESET_SUBPROPERTY", {"property": "preferences", "subproperty": "major"})
      this.$store.commit("RESET_SUBPROPERTY", {"property": "preferences", "subproperty": "fpso"})
    },
    async handleMajorChange() {
      this.resetBase();
      this.updateFpso();
      await this.loadProgram();
      this.reload();
    },
    async handleStartSemesterChange() {
      this.resetBase();
      this.updateFpso();
      await this.loadProgram();
      this.reload();
    },
    async handleFpsoChange() {
      this.resetBase();
      await this.loadProgram();
      this.reload();
    },
    updateFpso() {
      if (this.selectedMajor && this.preferences.startSemester) {
        // Filter FPSOs that are valid for the selected start semester
        const validFpsos = this.selectedMajor.fpsos.filter(fpso =>
            fpso.validStartSemesters.includes(this.preferences.startSemester)
        );

        // From the valid FPSOs, find the one that is also the default for the selected semester
        const defaultFpso = validFpsos.find(fpso =>
            fpso.defaultForSemesters.includes(this.preferences.startSemester)
        );

        // If a default FPSO is found, set it; otherwise, set the FPSO to the first valid one if available
        this.preferences.fpso = defaultFpso ? defaultFpso.id : (validFpsos[0] ? validFpsos[0].id : '');
        this.loadProgram();
      }
    },
    handleStructureChange(structureId, option) {
      this.selectStructureOption({structureId, option});

      if (option === 'none') {
        this.reload();
        return;
      }

      const structure = this.program.structures.find(struc => struc.id === structureId);
      if (structure === undefined) return;
      const structureEl = structure.elements.find(elem => elem.id === option);
      if (structureEl === undefined && option !== 'none') return;

      if (structureEl.exclusive) {
        const unselectedStructureElModules = structure.elements.filter(elem => elem.id !== option)
            .map(elem => elem.blocks)
            .flat()
            .map(blocks => blocks.modules)
            .flat()

        this.preferences.passedModules.filter(module => unselectedStructureElModules.includes(module))
            .forEach(module => {
              this.$store.commit('REMOVE_PASSED_MODULE', module);
            });

        this.preferences.desiredModules.filter(module => unselectedStructureElModules.includes(module))
            .forEach(module => {
              this.$store.commit('REMOVE_DESIRED_MODULE', module);
            });

        Object.keys(this.preferences.desiredInSemModules)
            .filter(module => unselectedStructureElModules.includes(module))
            .forEach(module => {
              this.$store.commit('REMOVE_DESIRED_IN_SEM_MODULE', module);
            });

        this.preferences.undesiredModules.filter(module => unselectedStructureElModules.includes(module))
            .forEach(module => {
              this.$store.commit('REMOVE_UNDESIRED_MODULE', module)
            });
      } else {
        const requiredModules = structureEl.blocks.filter(block => block.allRequired)
            .flat()
            .map(block => block.modules)
            .flat();
        requiredModules.forEach(module =>
            this.$store.commit('REMOVE_UNDESIRED_MODULE', module)
        );
      }

      this.reload();
    },
    getFilteredElements(elements, structureId) {
      // if structure id is specialization_1 or specialization_2, filter out the other option,
      // i.e. all other selected options, except for the one in the current structure
      if (structureId === 'specialization_1' || structureId === 'specialization_2') {
        const selectedOptions = Object.values(this.selectedStructureOptions).filter(Boolean);
        return elements.filter(elem => !selectedOptions.includes(elem.id) || elem.id === this.selectedStructureOptions[structureId]);
      }
  
      const structure = this.program.structures.find(struc => struc.id === structureId);
      // Check if the structure has a depends_on field
      if (structure?.dependsOn) {
        const selectedDependencyId = this.selectedStructureOptions[structure.dependsOn];
        // Filter elements where choice matches the selected element id of the structure on which this structure depends
        return elements.filter(elem => elem.choice === selectedDependencyId);
      }

      return elements;
    },
    toggleAdvancedFolded() {
      this.isAdvancedFolded = !this.isAdvancedFolded;
    },
    debounce(func, wait, immediate) {
      let timeout;
      return function () {
        const context = this, args = arguments;
        const later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    }
  }
};
</script>

<style scoped>
.sidebar {
  width: 20%;
  min-width: 250px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #9ABCE4;
  overflow-y: auto;
}

.sidebar-segment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
}

.sidebar-segment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #5E94D4;
}

.foldable {
  cursor: pointer;
}

.sidebar-title {
  font-weight: bold;
  margin-right: 10px;
}

.sidebar-segment-body {
  display: flex;
  flex-direction: column;
  padding: 15px 20px 20px 20px;
  gap: 10px;
}

.folded .sidebar-segment-body {
  display: none;
}

.icon-expand {
  font-size: 30px;
  transition: transform 0.3s ease;
}

.icon-expand:not(.folded) {
  transform: rotate(180deg);
}

.preference {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.preference--row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.preference-desc {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.preference input[type="text"],
.preference input[type="number"],
.preference input[type="checkbox"],
.preference select {
  border: none;
  color: #6A757E;
  font-family: inherit;
  padding: 5px 0;
  border-radius: 4px;
}

.preference input[type="text"]:not(:disabled),
.preference input[type="checkbox"]:not(:disabled),
.preference select:not(:disabled) {
  cursor: pointer;
}

.modal-controls {
  display: flex;
  flex-direction: column;
  margin: 30px 0 20px 0;
  gap: 15px;
  font-size: 90%;
}

.modal-control {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: none;
  font-size: inherit;
  color: #6A757E;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
  transition: box-shadow 0.3s;
}

.modal-control:not(:disabled):hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)
}

.modal-control:disabled {
  opacity: 0.7;
  cursor: auto;
}

.modal-control .material-symbols-outlined {
  color: #6A757E;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.modal-control:not(:disabled):hover .material-symbols-outlined {
  opacity: 1;
}

.modal-control-text {
  text-align: left;
  margin-right: 5px;
}
</style>
  

